import Clerk from "@clerk/clerk-js";

export default defineNuxtPlugin(async (nuxtApp) => {
  const runtimeConfig = useRuntimeConfig();
  const app = nuxtApp.vueApp;
  let clerk: Clerk | null = null;
  clerk = new Clerk(runtimeConfig.public.clerkPublishableKey);
  app.provide("clerk", clerk);
  app.config.globalProperties.$clerk = clerk;
  await clerk?.load();

  // if (runtimeConfig.public.isLocalDevelopment) {
  //   clerk = new Clerk(runtimeConfig.public.clerkPublishableKey, {
  //     // TODO: Update domain to port 3005, may need to be added within Clerk
  //     domain: "localhost:3000",
  //   });
  // } else {
  //   clerk = new Clerk(runtimeConfig.public.clerkPublishableKey);
  // }

  // app.provide("clerk", clerk);
  // app.config.globalProperties.$clerk = clerk;

  // if (runtimeConfig.public.isLocalDevelopment) {
  //   await clerk?.load({
  //     isSatellite: true,
  //     signInUrl: "http://localhost:3000",
  //   });
  // } else {
  //   await clerk?.load();
  // }
});
