import { mobileRestrictedRoutesConfig } from "../utils/constants";

export default defineNuxtRouteMiddleware(async (to) => {
  const routeRestrictionStore = useRouteRestrictionStore();

  const isMobileDevice = window.matchMedia("(max-width: 640px)").matches;
  if (isMobileDevice) {
    const matechedRestrictedRoute = mobileRestrictedRoutesConfig.find(
      ({ route }) => to.path.startsWith(route)
    );
    if (matechedRestrictedRoute) {
      routeRestrictionStore.setShowWarning(
        true,
        matechedRestrictedRoute.headerText,
        matechedRestrictedRoute.bodyText
      );
      return navigateTo({ path: matechedRestrictedRoute.redirectUrl });
    }
  }
});
