import validate from "/data/runners/three/_work/apps-monorepo/apps-monorepo/node_modules/.pnpm/nuxt@3.14.159_@types+node@20.17.6_eslint@8.57.1_typescript@5.4.5_vite@5.4.11/node_modules/nuxt/dist/pages/runtime/validate.js";
import device_45route_45restiction_45global from "/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app-documents/middleware/device-route-restiction.global.ts";
import query_45global from "/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app/middleware/query.global.ts";
import session_45global from "/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app/middleware/session.global.ts";
import workspaces_45global from "/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app/middleware/workspaces.global.ts";
import manifest_45route_45rule from "/data/runners/three/_work/apps-monorepo/apps-monorepo/node_modules/.pnpm/nuxt@3.14.159_@types+node@20.17.6_eslint@8.57.1_typescript@5.4.5_vite@5.4.11/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  device_45route_45restiction_45global,
  query_45global,
  session_45global,
  workspaces_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {}