import { z } from "zod";

const filters = {
  walletAddress(address: string) {
    if (!address || address?.length <= 0 || typeof address !== "string") return;

    const firstSixChars = address.slice(0, 6);
    const lastFourChars = address.slice(-4);

    return `${firstSixChars}...${lastFourChars}`;
  },
  walletNetwork(network: string) {
    const runtimeConfig = useRuntimeConfig();
    if (!network || network?.length <= 0 || typeof network !== "string") return;

    if (network === "arbitrum") {
      return runtimeConfig.public.NODE_ENV === "production" ? "arb1:" : "arb4:";
    }

    return runtimeConfig.public.NODE_ENV === "production" ? "eth:" : "goer:";
  },
  isZodObject(schema: z.ZodTypeAny): schema is z.AnyZodObject {
    if (schema._def.typeName === "ZodObject") return true;
    return false;
  },
  isZodArray(schema: z.ZodTypeAny): schema is z.ZodArray<any> {
    if (schema._def.typeName === "ZodArray") return true;
    return false;
  },
};

export default defineNuxtPlugin((nuxtApp) => {
  const app = nuxtApp.vueApp;
  app.config.globalProperties.$filters = filters;
  app.provide("filters", filters);
});

export type Filters = Record<keyof typeof filters, (value: string) => void>;
