import { defineStore } from "pinia";

export const useRouteRestrictionStore = defineStore("routeRestriction", {
  state: () => ({
    showWarning: false,
    headerText: "",
    bodyText: "",
  }),

  actions: {
    setShowWarning(showWarning: boolean, headerText = "", bodyText = "") {
      this.showWarning = showWarning;
      this.headerText = headerText;
      this.bodyText = bodyText;
    },
  },
});
