import { z } from 'zod'
import { isIBAN } from './validations.js'

export const riseid = z.string().length(42).brand<'Riseid'>()
export type Riseid = z.infer<typeof riseid>
export const companyRiseid = z.string().length(42).brand<'CompanyRiseid'>()
export type CompanyRiseid = z.infer<typeof companyRiseid>

export const teamRiseid = z.string().length(42).brand<'TeamRiseid'>()
export type TeamRiseid = z.infer<typeof teamRiseid>

export const userRiseid = z.string().length(42).brand<'UserRiseid'>()
export type UserRiseid = z.infer<typeof userRiseid>

export const allRiseids = z.union([companyRiseid, teamRiseid, userRiseid])
export type AllRiseids = z.infer<typeof allRiseids>

export const email = z.string().email().brand<'Email'>().describe('Email')
export type Email = z.infer<typeof email>

export const iban = z
	.string()
	.refine((str) => isIBAN(str), { message: 'Invalid IBAN' })
	.brand<'Iban'>()
	.describe('IBAN')
export type Iban = z.infer<typeof iban>

export const nanoid = z.string().length(15).brand<'Nanoid'>()
export type Nanoid = z.infer<typeof nanoid>

export const companyNanoid = z
	.string()
	.trim()
	.length(15)
	.startsWith('co-')
	.brand<'CompanyNanoid'>()
	.describe('CompanyNanoid')

export const certificationNanoid = z
	.string()
	.trim()
	.length(15)
	.startsWith('ce-')
	.brand<'CertificationNanoid'>()
	.describe('CertificationNanoid')

export type CertificationNanoid = z.infer<typeof certificationNanoid>

export const payScheduleNanoid = z
	.string()
	.trim()
	.length(15)
	.startsWith('ps-')
	.brand<'PayScheduleNanoid'>()
	.describe('PayScheduleNanoid')

export type PayScheduleNanoid = z.infer<typeof payScheduleNanoid>

export const paymentGroupNanoid = z
	.string()
	.trim()
	.length(15)
	.startsWith('pg-')
	.brand<'PaymentGroupNanoid'>()
	.describe('PaymentGroupNanoid')

export type PaymentGroupNanoid = z.infer<typeof paymentGroupNanoid>

export const paymentNanoid = z
	.string()
	.trim()
	.length(15)
	.startsWith('pa-')
	.brand<'PaymentNanoid'>()
	.describe('PaymentNanoid')

export type PaymentNanoid = z.infer<typeof paymentNanoid>

export const invoiceNanoid = z
	.string()
	.trim()
	.length(15)
	.startsWith('iv-')
	.brand<'InvoiceNanoid'>()
	.describe('InvoiceNanoid')

export type InvoiceNanoid = z.infer<typeof invoiceNanoid>

export const companyOwnerNanoid = z
	.string()
	.trim()
	.length(15)
	.startsWith('ow-')
	.brand<'CompanyOwnerNanoid'>()
	.describe('CompanyOwnerNanoid')

export type CompanyOwnerNanoid = z.infer<typeof companyOwnerNanoid>
export type CompanyNanoid = z.infer<typeof companyNanoid>

export const teamNanoid = z
	.string()
	.trim()
	.length(15)
	.startsWith('te-')
	.brand<'TeamNanoid'>()
	.describe('TeamNanoid')

export type TeamNanoid = z.infer<typeof teamNanoid>

export const userNanoid = z
	.string()
	.trim()
	.length(15)
	.startsWith('us-')
	.brand<'UserNanoid'>()
	.describe('UserNanoid')
export type UserNanoid = z.infer<typeof userNanoid>

export const companyRoleNanoid = z
	.string()
	.trim()
	.length(15)
	.startsWith('cr-')
	.brand<'CompanyRoleNanoid'>()

export type CompanyRoleNanoid = z.infer<typeof companyRoleNanoid>

export const teamRoleNanoid = z
	.string()
	.trim()
	.length(15)
	.startsWith('tr-')
	.brand<'TeamRoleNanoid'>()
export type TeamRoleNanoid = z.infer<typeof teamRoleNanoid>

export const withdrawAccountNanoid = z
	.string()
	.trim()
	.length(15)
	.startsWith('wa-')
	.brand<'WithdrawAccountNanoid'>()
export type WithdrawAccountNanoid = z.infer<typeof withdrawAccountNanoid>

export const withdrawNanoid = z
	.string()
	.trim()
	.length(15)
	.startsWith('wd-')
	.brand<'WithdrawNanoid'>()
export type WithdrawNanoid = z.infer<typeof withdrawNanoid>

export const actionItemNanoid = z
	.string()
	.trim()
	.length(15)
	.startsWith('ai-')
	.brand<'ActionItemNanoid'>()
export type ActionItemNanoid = z.infer<typeof actionItemNanoid>

export const transactionNanoid = z
	.string()
	.trim()
	.length(15)
	.startsWith('tx-')
	.brand<'TransactionNanoid'>()
export type TransactionNanoid = z.infer<typeof transactionNanoid>
export const allNanoids = z.union([
	companyNanoid,
	teamNanoid,
	userNanoid,
	companyRoleNanoid,
	teamRoleNanoid,
])
export const allEntityNanoids = z.union([companyNanoid, teamNanoid, userNanoid])
export type AllEntityNanoids = z.infer<typeof allEntityNanoids>
export type AllNanoids = z.infer<typeof allNanoids>
export const inviteNanoid = z
	.string()
	.trim()
	.length(15)
	.startsWith('in-')
	.brand<'InviteNanoid'>()
export type InviteNanoid = z.infer<typeof inviteNanoid>

export type EntityTypesToBrand = {
	user: UserNanoid
	company: CompanyNanoid
	org_admin: CompanyRoleNanoid
	org_finance_admin: CompanyRoleNanoid
	org_viewer: CompanyRoleNanoid
	team: TeamNanoid
	team_admin: TeamRoleNanoid
	team_finance_admin: TeamRoleNanoid
	team_viewer: TeamRoleNanoid
	team_employee: TeamRoleNanoid
	contractor: TeamRoleNanoid
}
