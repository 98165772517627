import { default as indextgOR6INrT9Meta } from "/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app-documents/pages/[[scope]]/[[workspaceId]]/document-builder/create/index.vue?macro=true";
import { default as _91versionid_93ryCmG4ZvGNMeta } from "/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app-documents/pages/[[scope]]/[[workspaceId]]/document-builder/edit/[documentid]/[versionid].vue?macro=true";
import { default as indextfvNsbAc3sMeta } from "/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app-documents/pages/[[scope]]/[[workspaceId]]/document-builder/index.vue?macro=true";
import { default as _91versionid_93CelkxKOITSMeta } from "/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app-documents/pages/[[scope]]/[[workspaceId]]/document-builder/review/[documentid]/[versionid].vue?macro=true";
import { default as _91versionid_93E8TtXRl6okMeta } from "/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app-documents/pages/[[scope]]/[[workspaceId]]/document-builder/signers/[documentid]/[versionid].vue?macro=true";
import { default as indexX3oKJaTZxSMeta } from "/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app-documents/pages/[[scope]]/[[workspaceId]]/document-builder/view/[documentId]/[versionId]/index.vue?macro=true";
import { default as indexe7mdz6D1wXMeta } from "/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app-documents/pages/[[scope]]/[[workspaceId]]/document-list/index.vue?macro=true";
import { default as createMn8wUvgaBEMeta } from "/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app-documents/pages/[[scope]]/[[workspaceId]]/templates/create.vue?macro=true";
import { default as _91templateId_93Z9XrfpPKO1Meta } from "/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app-documents/pages/[[scope]]/[[workspaceId]]/templates/edit/[templateId].vue?macro=true";
import { default as editTHP8A5iOZ1Meta } from "/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app-documents/pages/[[scope]]/[[workspaceId]]/templates/edit.vue?macro=true";
import { default as indexEc2owe9x8aMeta } from "/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app-documents/pages/[[scope]]/[[workspaceId]]/templates/index.vue?macro=true";
import { default as indexQjeCMc6At5Meta } from "/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app-documents/pages/access-denied/index.vue?macro=true";
import { default as activityJX4B6kUrYBMeta } from "/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app/pages/account/activity.vue?macro=true";
import { default as addressVbvyyhzrNuMeta } from "/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app/pages/account/address.vue?macro=true";
import { default as developer9tYJRcal1qMeta } from "/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app/pages/account/developer.vue?macro=true";
import { default as _91organizationId_93PyFtLQ9VeeMeta } from "/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app/pages/account/organizations/[organizationId].vue?macro=true";
import { default as index6pRG5gm9NcMeta } from "/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app/pages/account/organizations/index.vue?macro=true";
import { default as perksdyKCiWccrXMeta } from "/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app/pages/account/perks.vue?macro=true";
import { default as profileQSMhw7MpPiMeta } from "/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app/pages/account/profile.vue?macro=true";
import { default as referralIlzYnLXcaQMeta } from "/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app/pages/account/referral.vue?macro=true";
import { default as riseidTqTZE8tkbRMeta } from "/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app/pages/account/riseid.vue?macro=true";
import { default as security4iItmV36JJMeta } from "/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app/pages/account/security.vue?macro=true";
import { default as settingsKiBSgvXelYMeta } from "/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app/pages/account/settings.vue?macro=true";
import { default as _91uuid_93ZVSsnIg0B1Meta } from "/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app/pages/auth/invite/[uuid].vue?macro=true";
import { default as _91token_93qVcbREAq6vMeta } from "/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app/pages/auth/invite/b2b/[token].vue?macro=true";
import { default as arbitrum_45walletm0XarGAXL4Meta } from "/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/accounts/add/arbitrum-wallet.vue?macro=true";
import { default as binancerHrPRMQTEFMeta } from "/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/accounts/add/binance.vue?macro=true";
import { default as coinbasesuxRZ7fU01Meta } from "/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/accounts/add/coinbase.vue?macro=true";
import { default as domestic_45usdbz77kJhnuOMeta } from "/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/accounts/add/domestic-usd.vue?macro=true";
import { default as ethereum_45walletPvaAMVp5lTMeta } from "/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/accounts/add/ethereum-wallet.vue?macro=true";
import { default as europecnyKHCmBXWMeta } from "/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/accounts/add/europe.vue?macro=true";
import { default as forexWpZy84OysMMeta } from "/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/accounts/add/forex.vue?macro=true";
import { default as gbpnAytLRF30BMeta } from "/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/accounts/add/gbp.vue?macro=true";
import { default as international_45usd_45defaultH2gqAPnGwgMeta } from "/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/accounts/add/international-usd-default.vue?macro=true";
import { default as international_45usdd2k3fejHQqMeta } from "/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/accounts/add/international-usd.vue?macro=true";
import { default as krakenFGBGV2aZBhMeta } from "/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/accounts/add/kraken.vue?macro=true";
import { default as ngnheyJ09hTTdMeta } from "/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/accounts/add/ngn.vue?macro=true";
import { default as addtKcFT6CZLaMeta } from "/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/accounts/add.vue?macro=true";
import { default as indexibCQhFKcnWMeta } from "/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/accounts/index.vue?macro=true";
import { default as dashboardL7drL38LDoMeta } from "/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/dashboard.vue?macro=true";
import { default as documentsSWqu26wQSHMeta } from "/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/documents.vue?macro=true";
import { default as completefcarZvIycOMeta } from "/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/history/complete.vue?macro=true";
import { default as pendingphgYmnKRdZMeta } from "/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/history/pending.vue?macro=true";
import { default as _91id_93pyxhawalZkMeta } from "/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/receivable/flat/[id].vue?macro=true";
import { default as indexWZlI4IoImKMeta } from "/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/receivable/flat/index.vue?macro=true";
import { default as indexzIotliMWs8Meta } from "/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/receivable/index.vue?macro=true";
import { default as tasksLtu7rnUQZfMeta } from "/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/tasks.vue?macro=true";
import { default as indexAfWBVYwHK0Meta } from "/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/time_entries/index.vue?macro=true";
import { default as _91nanoid_93w8Yv7s5NCyMeta } from "/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/time_entries/invoice/[nanoid].vue?macro=true";
import { default as indexZlL1ffQorYMeta } from "/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/time_entries/invoice/index.vue?macro=true";
import { default as crypto5yivN9hC2oMeta } from "/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/withdraw/[accountId]/crypto.vue?macro=true";
import { default as fiatKH96vcHOcPMeta } from "/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/withdraw/[accountId]/fiat.vue?macro=true";
import { default as directGavXxIsC5sMeta } from "/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/withdraw/[nanoId]/direct.vue?macro=true";
import { default as withdrawqHXK0PALAfMeta } from "/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/withdraw.vue?macro=true";
import { default as benefitsqaWQsT1sa6Meta } from "/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app/pages/employees/[workspaceId]/benefits.vue?macro=true";
import { default as dashboardxEtt3i641VMeta } from "/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app/pages/employees/[workspaceId]/dashboard.vue?macro=true";
import { default as documentso8gA5iYgblMeta } from "/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app/pages/employees/[workspaceId]/documents.vue?macro=true";
import { default as pay_45slipsgLp7DcswxqMeta } from "/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app/pages/employees/[workspaceId]/pay-slips.vue?macro=true";
import { default as reimbursementsRYDjuIACwKMeta } from "/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app/pages/employees/[workspaceId]/reimbursements.vue?macro=true";
import { default as tasks75UwUAc18aMeta } from "/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app/pages/employees/[workspaceId]/tasks.vue?macro=true";
import { default as withdrawXb1nmHYARJMeta } from "/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app/pages/employees/[workspaceId]/withdraw.vue?macro=true";
import { default as indexbVcdtRyTaNMeta } from "/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app/pages/index.vue?macro=true";
import { default as _91nanoid_938R5mWF4xDiMeta } from "/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app/pages/invites/[nanoid].vue?macro=true";
import { default as jwtFj8ql9mJnBMeta } from "/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app/pages/jwt.vue?macro=true";
import { default as administratorsZztmM122ZuMeta } from "/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/administrators.vue?macro=true";
import { default as dashboard2LPhYnAcloMeta } from "/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/dashboard.vue?macro=true";
import { default as documents8XwRTDhU1hMeta } from "/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/documents.vue?macro=true";
import { default as completeq9pZidXI1MMeta } from "/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/history/complete.vue?macro=true";
import { default as pendingUevVMnEFndMeta } from "/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/history/pending.vue?macro=true";
import { default as directTVoeqTgzmFMeta } from "/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/invites/direct.vue?macro=true";
import { default as warmd1vElhkomiMeta } from "/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/invites/warm.vue?macro=true";
import { default as invites9dOCIAZZWUMeta } from "/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/invites.vue?macro=true";
import { default as addressRQwERoWPctMeta } from "/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/settings/address.vue?macro=true";
import { default as detailsOSobaN0V7fMeta } from "/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/settings/details.vue?macro=true";
import { default as ownership4llD3toue4Meta } from "/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/settings/ownership.vue?macro=true";
import { default as settingsJEyauRPg73Meta } from "/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/settings/settings.vue?macro=true";
import { default as settingsf5C2kz8OebMeta } from "/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/settings.vue?macro=true";
import { default as subscriptionLNmLnFfGGVMeta } from "/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/subscription.vue?macro=true";
import { default as tasksJedcW6hap9Meta } from "/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/tasks.vue?macro=true";
import { default as addeiyJECHnJBMeta } from "/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/teams/add.vue?macro=true";
import { default as indexGCO2fD9sfaMeta } from "/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/teams/index.vue?macro=true";
import { default as balance_45transferndLlrKLdmyMeta } from "/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/treasury/balance-transfer.vue?macro=true";
import { default as blockchain_45addressagsNzKK0GDMeta } from "/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/treasury/deposit/blockchain-address.vue?macro=true";
import { default as wire5iXJrVH5RqMeta } from "/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/treasury/deposit/wire.vue?macro=true";
import { default as depositHpQg1xdhP4Meta } from "/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/treasury/deposit.vue?macro=true";
import { default as playgroundfVP0SVRhsEMeta } from "/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app/pages/playground.vue?macro=true";
import { default as _91_46_46_46id_93hvGhjvOQCCMeta } from "/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app/pages/receipts/[...id].vue?macro=true";
import { default as external4i2iJ0WXWsMeta } from "/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app/pages/receipts/external.vue?macro=true";
import { default as sessionVAhVEd1abrMeta } from "/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app-documents/pages/session.vue?macro=true";
import { default as _2fawyeqn4H41pMeta } from "/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app/pages/sign-in/2fa.vue?macro=true";
import { default as callbackuEwgYya4ZxMeta } from "/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app-documents/pages/sign-in/callback.vue?macro=true";
import { default as codeDtIHTmT5HjMeta } from "/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app/pages/sign-in/code.vue?macro=true";
import { default as indexr5xcMfLk7SMeta } from "/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app/pages/sign-in/index.vue?macro=true";
import { default as indexHIYCgKEmLDMeta } from "/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app-documents/pages/sign-out/index.vue?macro=true";
import { default as sign_45outid6IiYYNuNMeta } from "/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app/pages/sign-out.vue?macro=true";
import { default as codefAMAIYP0rUMeta } from "/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app/pages/sign-up/code.vue?macro=true";
import { default as indexzEUullWYK9Meta } from "/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app/pages/sign-up/index.vue?macro=true";
import { default as waitinggA1WdOu2G0Meta } from "/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app/pages/sign-up/waiting.vue?macro=true";
import { default as signi3igHCnnWVMeta } from "/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app/pages/sign.vue?macro=true";
import { default as dashboardL625AqE9GwMeta } from "/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/dashboard.vue?macro=true";
import { default as blockchain_45addresshOpBaWYuOZMeta } from "/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/deposit/blockchain-address.vue?macro=true";
import { default as wireEwajdYG0dmMeta } from "/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/deposit/wire.vue?macro=true";
import { default as deposite4TKNIwOvFMeta } from "/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/deposit.vue?macro=true";
import { default as documents6crgX6WUXMMeta } from "/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/documents.vue?macro=true";
import { default as complete8wOGl8qczRMeta } from "/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/history/complete.vue?macro=true";
import { default as pendingVsOS5C8QGjMeta } from "/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/history/pending.vue?macro=true";
import { default as batchAV60skGusVMeta } from "/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/invites/batch.vue?macro=true";
import { default as directnu71U1GhOCMeta } from "/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/invites/direct.vue?macro=true";
import { default as warmWfeYwFqmn2Meta } from "/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/invites/warm.vue?macro=true";
import { default as invitesr6OP8BHHiUMeta } from "/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/invites.vue?macro=true";
import { default as benefitsWnEOeXiIWWMeta } from "/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/members/[userId]/benefits.vue?macro=true";
import { default as summary0vQaS0RSQIMeta } from "/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/members/[userId]/summary.vue?macro=true";
import { default as _91userId_93FaZQCjpCnOMeta } from "/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/members/[userId].vue?macro=true";
import { default as indexaJ1FcWQesLMeta } from "/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/members/index.vue?macro=true";
import { default as csvkJ2HtnyphfMeta } from "/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/pay/batch/csv.vue?macro=true";
import { default as index4N7oOXrFMqMeta } from "/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/pay/batch/index.vue?macro=true";
import { default as indexF65goW5cVAMeta } from "/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/pay/index.vue?macro=true";
import { default as directzsH4KipEAJMeta } from "/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/pay/individual/[userId]/direct.vue?macro=true";
import { default as instant9UxElRMsiEMeta } from "/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/pay/individual/[userId]/instant.vue?macro=true";
import { default as milestonesDUE4f6OWFMeta } from "/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/pay/individual/[userId]/milestone.vue?macro=true";
import { default as onceylzVsj5lL4Meta } from "/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/pay/individual/[userId]/once.vue?macro=true";
import { default as recurringAvTXU2sDDlMeta } from "/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/pay/individual/[userId]/recurring.vue?macro=true";
import { default as _91userId_93mL4Izfz8bbMeta } from "/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/pay/individual/[userId].vue?macro=true";
import { default as payableROaYVWQa5SMeta } from "/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/payable.vue?macro=true";
import { default as indexeJVHH0xM70Meta } from "/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/payroll/index.vue?macro=true";
import { default as settingsDxZRKLoJnfMeta } from "/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/settings.vue?macro=true";
import { default as taskshCM90cw2LAMeta } from "/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/tasks.vue?macro=true";
export default [
  {
    name: "scope-workspaceId-document-builder-create",
    path: "/:scope?/:workspaceId?/document-builder/create",
    meta: indextgOR6INrT9Meta || {},
    component: () => import("/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app-documents/pages/[[scope]]/[[workspaceId]]/document-builder/create/index.vue")
  },
  {
    name: "scope-workspaceId-document-builder-edit-documentid-versionid",
    path: "/:scope?/:workspaceId?/document-builder/edit/:documentid()/:versionid()",
    meta: _91versionid_93ryCmG4ZvGNMeta || {},
    component: () => import("/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app-documents/pages/[[scope]]/[[workspaceId]]/document-builder/edit/[documentid]/[versionid].vue")
  },
  {
    name: "scope-workspaceId-document-builder",
    path: "/:scope?/:workspaceId?/document-builder",
    component: () => import("/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app-documents/pages/[[scope]]/[[workspaceId]]/document-builder/index.vue")
  },
  {
    name: "scope-workspaceId-document-builder-review-documentid-versionid",
    path: "/:scope?/:workspaceId?/document-builder/review/:documentid()/:versionid()",
    meta: _91versionid_93CelkxKOITSMeta || {},
    component: () => import("/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app-documents/pages/[[scope]]/[[workspaceId]]/document-builder/review/[documentid]/[versionid].vue")
  },
  {
    name: "scope-workspaceId-document-builder-signers-documentid-versionid",
    path: "/:scope?/:workspaceId?/document-builder/signers/:documentid()/:versionid()",
    meta: _91versionid_93E8TtXRl6okMeta || {},
    component: () => import("/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app-documents/pages/[[scope]]/[[workspaceId]]/document-builder/signers/[documentid]/[versionid].vue")
  },
  {
    name: "scope-workspaceId-document-builder-view-documentId-versionId",
    path: "/:scope?/:workspaceId?/document-builder/view/:documentId()/:versionId()",
    meta: indexX3oKJaTZxSMeta || {},
    component: () => import("/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app-documents/pages/[[scope]]/[[workspaceId]]/document-builder/view/[documentId]/[versionId]/index.vue")
  },
  {
    name: "scope-workspaceId-document-list",
    path: "/:scope?/:workspaceId?/document-list",
    meta: indexe7mdz6D1wXMeta || {},
    component: () => import("/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app-documents/pages/[[scope]]/[[workspaceId]]/document-list/index.vue")
  },
  {
    name: "scope-workspaceId-templates-create",
    path: "/:scope?/:workspaceId?/templates/create",
    meta: createMn8wUvgaBEMeta || {},
    component: () => import("/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app-documents/pages/[[scope]]/[[workspaceId]]/templates/create.vue")
  },
  {
    name: "scope-workspaceId-templates-edit",
    path: "/:scope?/:workspaceId?/templates/edit",
    meta: editTHP8A5iOZ1Meta || {},
    component: () => import("/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app-documents/pages/[[scope]]/[[workspaceId]]/templates/edit.vue"),
    children: [
  {
    name: "scope-workspaceId-templates-edit-templateId",
    path: ":templateId()",
    meta: _91templateId_93Z9XrfpPKO1Meta || {},
    component: () => import("/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app-documents/pages/[[scope]]/[[workspaceId]]/templates/edit/[templateId].vue")
  }
]
  },
  {
    name: "scope-workspaceId-templates",
    path: "/:scope?/:workspaceId?/templates",
    meta: indexEc2owe9x8aMeta || {},
    component: () => import("/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app-documents/pages/[[scope]]/[[workspaceId]]/templates/index.vue")
  },
  {
    name: "access-denied",
    path: "/access-denied",
    meta: indexQjeCMc6At5Meta || {},
    component: () => import("/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app-documents/pages/access-denied/index.vue")
  },
  {
    name: "account-activity",
    path: "/account/activity",
    meta: activityJX4B6kUrYBMeta || {},
    component: () => import("/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app/pages/account/activity.vue")
  },
  {
    name: "account-address",
    path: "/account/address",
    meta: addressVbvyyhzrNuMeta || {},
    component: () => import("/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app/pages/account/address.vue")
  },
  {
    name: "account-developer",
    path: "/account/developer",
    meta: developer9tYJRcal1qMeta || {},
    component: () => import("/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app/pages/account/developer.vue")
  },
  {
    name: "account-organizations-organizationId",
    path: "/account/organizations/:organizationId()",
    meta: _91organizationId_93PyFtLQ9VeeMeta || {},
    component: () => import("/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app/pages/account/organizations/[organizationId].vue")
  },
  {
    name: "account-organizations",
    path: "/account/organizations",
    meta: index6pRG5gm9NcMeta || {},
    component: () => import("/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app/pages/account/organizations/index.vue")
  },
  {
    name: "account-perks",
    path: "/account/perks",
    meta: perksdyKCiWccrXMeta || {},
    component: () => import("/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app/pages/account/perks.vue")
  },
  {
    name: "account-profile",
    path: "/account/profile",
    meta: profileQSMhw7MpPiMeta || {},
    component: () => import("/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app/pages/account/profile.vue")
  },
  {
    name: "account-referral",
    path: "/account/referral",
    meta: referralIlzYnLXcaQMeta || {},
    component: () => import("/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app/pages/account/referral.vue")
  },
  {
    name: "account-riseid",
    path: "/account/riseid",
    meta: riseidTqTZE8tkbRMeta || {},
    component: () => import("/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app/pages/account/riseid.vue")
  },
  {
    name: "account-security",
    path: "/account/security",
    meta: security4iItmV36JJMeta || {},
    component: () => import("/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app/pages/account/security.vue")
  },
  {
    name: "account-settings",
    path: "/account/settings",
    meta: settingsKiBSgvXelYMeta || {},
    component: () => import("/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app/pages/account/settings.vue")
  },
  {
    name: "auth-invite-uuid",
    path: "/auth/invite/:uuid()",
    component: () => import("/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app/pages/auth/invite/[uuid].vue")
  },
  {
    name: "auth-invite-b2b-token",
    path: "/auth/invite/b2b/:token()",
    component: () => import("/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app/pages/auth/invite/b2b/[token].vue")
  },
  {
    name: "contractors-workspaceId-accounts-add",
    path: "/contractors/:workspaceId()/accounts/add",
    meta: addtKcFT6CZLaMeta || {},
    component: () => import("/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/accounts/add.vue"),
    children: [
  {
    name: "contractors-workspaceId-accounts-add-arbitrum-wallet",
    path: "arbitrum-wallet",
    component: () => import("/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/accounts/add/arbitrum-wallet.vue")
  },
  {
    name: "contractors-workspaceId-accounts-add-binance",
    path: "binance",
    component: () => import("/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/accounts/add/binance.vue")
  },
  {
    name: "contractors-workspaceId-accounts-add-coinbase",
    path: "coinbase",
    component: () => import("/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/accounts/add/coinbase.vue")
  },
  {
    name: "contractors-workspaceId-accounts-add-domestic-usd",
    path: "domestic-usd",
    component: () => import("/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/accounts/add/domestic-usd.vue")
  },
  {
    name: "contractors-workspaceId-accounts-add-ethereum-wallet",
    path: "ethereum-wallet",
    component: () => import("/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/accounts/add/ethereum-wallet.vue")
  },
  {
    name: "contractors-workspaceId-accounts-add-europe",
    path: "europe",
    component: () => import("/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/accounts/add/europe.vue")
  },
  {
    name: "contractors-workspaceId-accounts-add-forex",
    path: "forex",
    component: () => import("/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/accounts/add/forex.vue")
  },
  {
    name: "contractors-workspaceId-accounts-add-gbp",
    path: "gbp",
    component: () => import("/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/accounts/add/gbp.vue")
  },
  {
    name: "contractors-workspaceId-accounts-add-international-usd-default",
    path: "international-usd-default",
    component: () => import("/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/accounts/add/international-usd-default.vue")
  },
  {
    name: "contractors-workspaceId-accounts-add-international-usd",
    path: "international-usd",
    component: () => import("/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/accounts/add/international-usd.vue")
  },
  {
    name: "contractors-workspaceId-accounts-add-kraken",
    path: "kraken",
    component: () => import("/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/accounts/add/kraken.vue")
  },
  {
    name: "contractors-workspaceId-accounts-add-ngn",
    path: "ngn",
    component: () => import("/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/accounts/add/ngn.vue")
  }
]
  },
  {
    name: "contractors-workspaceId-accounts",
    path: "/contractors/:workspaceId()/accounts",
    meta: indexibCQhFKcnWMeta || {},
    component: () => import("/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/accounts/index.vue")
  },
  {
    name: "contractors-workspaceId-dashboard",
    path: "/contractors/:workspaceId()/dashboard",
    meta: dashboardL7drL38LDoMeta || {},
    component: () => import("/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/dashboard.vue")
  },
  {
    name: "contractors-workspaceId-documents",
    path: "/contractors/:workspaceId()/documents",
    meta: documentsSWqu26wQSHMeta || {},
    component: () => import("/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/documents.vue")
  },
  {
    name: "contractors-workspaceId-history-complete",
    path: "/contractors/:workspaceId()/history/complete",
    meta: completefcarZvIycOMeta || {},
    component: () => import("/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/history/complete.vue")
  },
  {
    name: "contractors-workspaceId-history-pending",
    path: "/contractors/:workspaceId()/history/pending",
    meta: pendingphgYmnKRdZMeta || {},
    component: () => import("/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/history/pending.vue")
  },
  {
    name: "contractors-workspaceId-receivable-flat-id",
    path: "/contractors/:workspaceId()/receivable/flat/:id()",
    meta: _91id_93pyxhawalZkMeta || {},
    component: () => import("/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/receivable/flat/[id].vue")
  },
  {
    name: "contractors-workspaceId-receivable-flat",
    path: "/contractors/:workspaceId()/receivable/flat",
    meta: indexWZlI4IoImKMeta || {},
    component: () => import("/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/receivable/flat/index.vue")
  },
  {
    name: "contractors-workspaceId-receivable",
    path: "/contractors/:workspaceId()/receivable",
    meta: indexzIotliMWs8Meta || {},
    component: () => import("/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/receivable/index.vue")
  },
  {
    name: "contractors-workspaceId-tasks",
    path: "/contractors/:workspaceId()/tasks",
    meta: tasksLtu7rnUQZfMeta || {},
    component: () => import("/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/tasks.vue")
  },
  {
    name: "contractors-workspaceId-time_entries",
    path: "/contractors/:workspaceId()/time_entries",
    meta: indexAfWBVYwHK0Meta || {},
    component: () => import("/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/time_entries/index.vue")
  },
  {
    name: "contractors-workspaceId-time_entries-invoice-nanoid",
    path: "/contractors/:workspaceId()/time_entries/invoice/:nanoid()",
    meta: _91nanoid_93w8Yv7s5NCyMeta || {},
    component: () => import("/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/time_entries/invoice/[nanoid].vue")
  },
  {
    name: "contractors-workspaceId-time_entries-invoice",
    path: "/contractors/:workspaceId()/time_entries/invoice",
    meta: indexZlL1ffQorYMeta || {},
    component: () => import("/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/time_entries/invoice/index.vue")
  },
  {
    name: "contractors-workspaceId-withdraw",
    path: "/contractors/:workspaceId()/withdraw",
    meta: withdrawqHXK0PALAfMeta || {},
    component: () => import("/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/withdraw.vue"),
    children: [
  {
    name: "contractors-workspaceId-withdraw-accountId-crypto",
    path: ":accountId()/crypto",
    component: () => import("/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/withdraw/[accountId]/crypto.vue")
  },
  {
    name: "contractors-workspaceId-withdraw-accountId-fiat",
    path: ":accountId()/fiat",
    component: () => import("/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/withdraw/[accountId]/fiat.vue")
  },
  {
    name: "contractors-workspaceId-withdraw-nanoId-direct",
    path: ":nanoId()/direct",
    component: () => import("/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/withdraw/[nanoId]/direct.vue")
  }
]
  },
  {
    name: "employees-workspaceId-benefits",
    path: "/employees/:workspaceId()/benefits",
    meta: benefitsqaWQsT1sa6Meta || {},
    component: () => import("/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app/pages/employees/[workspaceId]/benefits.vue")
  },
  {
    name: "employees-workspaceId-dashboard",
    path: "/employees/:workspaceId()/dashboard",
    meta: dashboardxEtt3i641VMeta || {},
    component: () => import("/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app/pages/employees/[workspaceId]/dashboard.vue")
  },
  {
    name: "employees-workspaceId-documents",
    path: "/employees/:workspaceId()/documents",
    meta: documentso8gA5iYgblMeta || {},
    component: () => import("/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app/pages/employees/[workspaceId]/documents.vue")
  },
  {
    name: "employees-workspaceId-pay-slips",
    path: "/employees/:workspaceId()/pay-slips",
    meta: pay_45slipsgLp7DcswxqMeta || {},
    component: () => import("/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app/pages/employees/[workspaceId]/pay-slips.vue")
  },
  {
    name: "employees-workspaceId-reimbursements",
    path: "/employees/:workspaceId()/reimbursements",
    meta: reimbursementsRYDjuIACwKMeta || {},
    component: () => import("/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app/pages/employees/[workspaceId]/reimbursements.vue")
  },
  {
    name: "employees-workspaceId-tasks",
    path: "/employees/:workspaceId()/tasks",
    meta: tasks75UwUAc18aMeta || {},
    component: () => import("/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app/pages/employees/[workspaceId]/tasks.vue")
  },
  {
    name: "employees-workspaceId-withdraw",
    path: "/employees/:workspaceId()/withdraw",
    meta: withdrawXb1nmHYARJMeta || {},
    component: () => import("/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app/pages/employees/[workspaceId]/withdraw.vue")
  },
  {
    name: "index",
    path: "/",
    meta: indexbVcdtRyTaNMeta || {},
    component: () => import("/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app/pages/index.vue")
  },
  {
    name: "invites-nanoid",
    path: "/invites/:nanoid()",
    meta: _91nanoid_938R5mWF4xDiMeta || {},
    component: () => import("/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app/pages/invites/[nanoid].vue")
  },
  {
    name: "jwt",
    path: "/jwt",
    component: () => import("/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app/pages/jwt.vue")
  },
  {
    name: "organizations-workspaceId-administrators",
    path: "/organizations/:workspaceId()/administrators",
    meta: administratorsZztmM122ZuMeta || {},
    component: () => import("/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/administrators.vue")
  },
  {
    name: "organizations-workspaceId-dashboard",
    path: "/organizations/:workspaceId()/dashboard",
    meta: dashboard2LPhYnAcloMeta || {},
    component: () => import("/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/dashboard.vue")
  },
  {
    name: "organizations-workspaceId-documents",
    path: "/organizations/:workspaceId()/documents",
    meta: documents8XwRTDhU1hMeta || {},
    component: () => import("/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/documents.vue")
  },
  {
    name: "organizations-workspaceId-history-complete",
    path: "/organizations/:workspaceId()/history/complete",
    meta: completeq9pZidXI1MMeta || {},
    component: () => import("/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/history/complete.vue")
  },
  {
    name: "organizations-workspaceId-history-pending",
    path: "/organizations/:workspaceId()/history/pending",
    meta: pendingUevVMnEFndMeta || {},
    component: () => import("/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/history/pending.vue")
  },
  {
    name: "organizations-workspaceId-invites",
    path: "/organizations/:workspaceId()/invites",
    meta: invites9dOCIAZZWUMeta || {},
    component: () => import("/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/invites.vue"),
    children: [
  {
    name: "organizations-workspaceId-invites-direct",
    path: "direct",
    component: () => import("/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/invites/direct.vue")
  },
  {
    name: "organizations-workspaceId-invites-warm",
    path: "warm",
    component: () => import("/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/invites/warm.vue")
  }
]
  },
  {
    name: "organizations-workspaceId-settings",
    path: "/organizations/:workspaceId()/settings",
    meta: settingsf5C2kz8OebMeta || {},
    component: () => import("/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/settings.vue"),
    children: [
  {
    name: "organizations-workspaceId-settings-address",
    path: "address",
    component: () => import("/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/settings/address.vue")
  },
  {
    name: "organizations-workspaceId-settings-details",
    path: "details",
    component: () => import("/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/settings/details.vue")
  },
  {
    name: "organizations-workspaceId-settings-ownership",
    path: "ownership",
    component: () => import("/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/settings/ownership.vue")
  },
  {
    name: "organizations-workspaceId-settings-settings",
    path: "settings",
    component: () => import("/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/settings/settings.vue")
  }
]
  },
  {
    name: "organizations-workspaceId-subscription",
    path: "/organizations/:workspaceId()/subscription",
    meta: subscriptionLNmLnFfGGVMeta || {},
    component: () => import("/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/subscription.vue")
  },
  {
    name: "organizations-workspaceId-tasks",
    path: "/organizations/:workspaceId()/tasks",
    meta: tasksJedcW6hap9Meta || {},
    component: () => import("/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/tasks.vue")
  },
  {
    name: "organizations-workspaceId-teams-add",
    path: "/organizations/:workspaceId()/teams/add",
    meta: addeiyJECHnJBMeta || {},
    component: () => import("/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/teams/add.vue")
  },
  {
    name: "organizations-workspaceId-teams",
    path: "/organizations/:workspaceId()/teams",
    meta: indexGCO2fD9sfaMeta || {},
    component: () => import("/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/teams/index.vue")
  },
  {
    name: "organizations-workspaceId-treasury-balance-transfer",
    path: "/organizations/:workspaceId()/treasury/balance-transfer",
    meta: balance_45transferndLlrKLdmyMeta || {},
    component: () => import("/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/treasury/balance-transfer.vue")
  },
  {
    name: "organizations-workspaceId-treasury-deposit",
    path: "/organizations/:workspaceId()/treasury/deposit",
    meta: depositHpQg1xdhP4Meta || {},
    component: () => import("/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/treasury/deposit.vue"),
    children: [
  {
    name: "organizations-workspaceId-treasury-deposit-blockchain-address",
    path: "blockchain-address",
    meta: blockchain_45addressagsNzKK0GDMeta || {},
    component: () => import("/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/treasury/deposit/blockchain-address.vue")
  },
  {
    name: "organizations-workspaceId-treasury-deposit-wire",
    path: "wire",
    meta: wire5iXJrVH5RqMeta || {},
    component: () => import("/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/treasury/deposit/wire.vue")
  }
]
  },
  {
    name: "playground",
    path: "/playground",
    component: () => import("/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app/pages/playground.vue")
  },
  {
    name: "receipts-id",
    path: "/receipts/:id(.*)*",
    meta: _91_46_46_46id_93hvGhjvOQCCMeta || {},
    component: () => import("/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app/pages/receipts/[...id].vue")
  },
  {
    name: "receipts-external",
    path: "/receipts/external",
    meta: external4i2iJ0WXWsMeta || {},
    component: () => import("/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app/pages/receipts/external.vue")
  },
  {
    name: "session",
    path: "/session",
    meta: sessionVAhVEd1abrMeta || {},
    component: () => import("/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app-documents/pages/session.vue")
  },
  {
    name: "sign-in-2fa",
    path: "/sign-in/2fa",
    component: () => import("/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app/pages/sign-in/2fa.vue")
  },
  {
    name: "sign-in-callback",
    path: "/sign-in/callback",
    component: () => import("/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app-documents/pages/sign-in/callback.vue")
  },
  {
    name: "sign-in-code",
    path: "/sign-in/code",
    meta: codeDtIHTmT5HjMeta || {},
    component: () => import("/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app/pages/sign-in/code.vue")
  },
  {
    name: "sign-in",
    path: "/sign-in",
    meta: indexr5xcMfLk7SMeta || {},
    component: () => import("/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app/pages/sign-in/index.vue")
  },
  {
    name: sign_45outid6IiYYNuNMeta?.name,
    path: "/sign-out",
    component: () => import("/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app/pages/sign-out.vue"),
    children: [
  {
    name: "sign-out",
    path: "",
    meta: indexHIYCgKEmLDMeta || {},
    component: () => import("/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app-documents/pages/sign-out/index.vue")
  }
]
  },
  {
    name: "sign-up-code",
    path: "/sign-up/code",
    meta: codefAMAIYP0rUMeta || {},
    component: () => import("/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app/pages/sign-up/code.vue")
  },
  {
    name: "sign-up",
    path: "/sign-up",
    meta: indexzEUullWYK9Meta || {},
    component: () => import("/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app/pages/sign-up/index.vue")
  },
  {
    name: "sign-up-waiting",
    path: "/sign-up/waiting",
    meta: waitinggA1WdOu2G0Meta || {},
    component: () => import("/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app/pages/sign-up/waiting.vue")
  },
  {
    name: "sign",
    path: "/sign",
    meta: signi3igHCnnWVMeta || {},
    component: () => import("/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app/pages/sign.vue")
  },
  {
    name: "teams-workspaceId-dashboard",
    path: "/teams/:workspaceId()/dashboard",
    meta: dashboardL625AqE9GwMeta || {},
    component: () => import("/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/dashboard.vue")
  },
  {
    name: "teams-workspaceId-deposit",
    path: "/teams/:workspaceId()/deposit",
    meta: deposite4TKNIwOvFMeta || {},
    component: () => import("/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/deposit.vue"),
    children: [
  {
    name: "teams-workspaceId-deposit-blockchain-address",
    path: "blockchain-address",
    meta: blockchain_45addresshOpBaWYuOZMeta || {},
    component: () => import("/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/deposit/blockchain-address.vue")
  },
  {
    name: "teams-workspaceId-deposit-wire",
    path: "wire",
    meta: wireEwajdYG0dmMeta || {},
    component: () => import("/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/deposit/wire.vue")
  }
]
  },
  {
    name: "teams-workspaceId-documents",
    path: "/teams/:workspaceId()/documents",
    meta: documents6crgX6WUXMMeta || {},
    component: () => import("/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/documents.vue")
  },
  {
    name: "teams-workspaceId-history-complete",
    path: "/teams/:workspaceId()/history/complete",
    meta: complete8wOGl8qczRMeta || {},
    component: () => import("/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/history/complete.vue")
  },
  {
    name: "teams-workspaceId-history-pending",
    path: "/teams/:workspaceId()/history/pending",
    meta: pendingVsOS5C8QGjMeta || {},
    component: () => import("/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/history/pending.vue")
  },
  {
    name: "teams-workspaceId-invites",
    path: "/teams/:workspaceId()/invites",
    meta: invitesr6OP8BHHiUMeta || {},
    component: () => import("/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/invites.vue"),
    children: [
  {
    name: "teams-workspaceId-invites-batch",
    path: "batch",
    component: () => import("/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/invites/batch.vue")
  },
  {
    name: "teams-workspaceId-invites-direct",
    path: "direct",
    component: () => import("/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/invites/direct.vue")
  },
  {
    name: "teams-workspaceId-invites-warm",
    path: "warm",
    component: () => import("/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/invites/warm.vue")
  }
]
  },
  {
    name: "teams-workspaceId-members-userId",
    path: "/teams/:workspaceId()/members/:userId()",
    meta: _91userId_93FaZQCjpCnOMeta || {},
    component: () => import("/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/members/[userId].vue"),
    children: [
  {
    name: "teams-workspaceId-members-userId-benefits",
    path: "benefits",
    component: () => import("/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/members/[userId]/benefits.vue")
  },
  {
    name: "teams-workspaceId-members-userId-summary",
    path: "summary",
    component: () => import("/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/members/[userId]/summary.vue")
  }
]
  },
  {
    name: "teams-workspaceId-members",
    path: "/teams/:workspaceId()/members",
    meta: indexaJ1FcWQesLMeta || {},
    component: () => import("/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/members/index.vue")
  },
  {
    name: "teams-workspaceId-pay-batch-csv",
    path: "/teams/:workspaceId()/pay/batch/csv",
    meta: csvkJ2HtnyphfMeta || {},
    component: () => import("/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/pay/batch/csv.vue")
  },
  {
    name: "teams-workspaceId-pay-batch",
    path: "/teams/:workspaceId()/pay/batch",
    meta: index4N7oOXrFMqMeta || {},
    component: () => import("/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/pay/batch/index.vue")
  },
  {
    name: "teams-workspaceId-pay",
    path: "/teams/:workspaceId()/pay",
    meta: indexF65goW5cVAMeta || {},
    component: () => import("/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/pay/index.vue")
  },
  {
    name: "teams-workspaceId-pay-individual-userId",
    path: "/teams/:workspaceId()/pay/individual/:userId()",
    meta: _91userId_93mL4Izfz8bbMeta || {},
    component: () => import("/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/pay/individual/[userId].vue"),
    children: [
  {
    name: "teams-workspaceId-pay-individual-userId-direct",
    path: "direct",
    meta: directzsH4KipEAJMeta || {},
    component: () => import("/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/pay/individual/[userId]/direct.vue")
  },
  {
    name: "teams-workspaceId-pay-individual-userId-instant",
    path: "instant",
    meta: instant9UxElRMsiEMeta || {},
    component: () => import("/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/pay/individual/[userId]/instant.vue")
  },
  {
    name: "teams-workspaceId-pay-individual-userId-milestone",
    path: "milestone",
    meta: milestonesDUE4f6OWFMeta || {},
    component: () => import("/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/pay/individual/[userId]/milestone.vue")
  },
  {
    name: "teams-workspaceId-pay-individual-userId-once",
    path: "once",
    meta: onceylzVsj5lL4Meta || {},
    component: () => import("/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/pay/individual/[userId]/once.vue")
  },
  {
    name: "teams-workspaceId-pay-individual-userId-recurring",
    path: "recurring",
    meta: recurringAvTXU2sDDlMeta || {},
    component: () => import("/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/pay/individual/[userId]/recurring.vue")
  }
]
  },
  {
    name: "teams-workspaceId-payable",
    path: "/teams/:workspaceId()/payable",
    meta: payableROaYVWQa5SMeta || {},
    component: () => import("/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/payable.vue")
  },
  {
    name: "teams-workspaceId-payroll",
    path: "/teams/:workspaceId()/payroll",
    meta: indexeJVHH0xM70Meta || {},
    component: () => import("/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/payroll/index.vue")
  },
  {
    name: "teams-workspaceId-settings",
    path: "/teams/:workspaceId()/settings",
    meta: settingsDxZRKLoJnfMeta || {},
    component: () => import("/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/settings.vue")
  },
  {
    name: "teams-workspaceId-tasks",
    path: "/teams/:workspaceId()/tasks",
    meta: taskshCM90cw2LAMeta || {},
    component: () => import("/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/tasks.vue")
  }
]