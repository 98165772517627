import { type ClientPlugin, type NormalizeOAS, createClient } from "fets";
import { toast } from "vue-sonner";

import type openapi from "~/.nuxt/contracts/src/codegen/openapi/rise";

export default defineNuxtPlugin((nuxtApp) => {
  const app = nuxtApp.vueApp;
  const config = useRuntimeConfig();

  function myCustomPlugin(): ClientPlugin {
    return {
      async onRequestInit({ requestInit, path }) {
        function hasNonLetters(inputString: string) {
          const nonLetterRegex = /[^a-zA-Z]/;
          return nonLetterRegex.test(inputString);
        }
        const actionName = path
          .split("/")
          .filter((e) => !hasNonLetters(e))
          .join("");

        await app.config.globalProperties.$recaptchaLoaded();
        const token = await app.config.globalProperties.$recaptcha(actionName);

        const hasSession =
          app.config.globalProperties.$clerk?.session?.status === "active";

        if (!hasSession) {
          if (config.public.isLocalDevelopment) return;
          // @ts-expect-error Missing type
          nuxtApp.$router.push("/sign-out");
          return;
        }

        const jwt = await app.config.globalProperties.$clerk.session.getToken({
          template: "User_Template",
        });

        requestInit.headers = {
          ...requestInit.headers,
          Authorization: `Bearer ${jwt}`,
          "x-recaptcha-token": token,
          "x-recaptcha-action": actionName,
        };
      },
      onFetch({ fetchFn, setFetchFn }) {
        setFetchFn(async (input, init) => {
          const response = await fetchFn(input, init);
          if (!response.ok) {
            const responseJson = await response.json();
            toast.error(responseJson?.data || "Api error");
          }
          return response;
        });
      },
      onResponse({ response }) {
        if (response.status === 403) {
          navigateTo("/access-denied");
        }
      },
    };
  }

  const client = createClient<NormalizeOAS<typeof openapi>>({
    // @ts-expect-error Missing type
    endpoint: config.public.apiUrl as "https://dev-pay-api.riseworks.io",
    plugins: [myCustomPlugin()],
  });

  app.config.globalProperties.$client = client;
  app.provide("client", client);
});
