import { useCookies } from "@vueuse/integrations/useCookies";
import { useActionItemsStore } from "~/stores/api/action_items";

export default defineNuxtRouteMiddleware(async (to) => {
  if (to.query.oauth) return;
  const userStore = useUserStore();

  if (
    localStorage.theme === "dark" ||
    (!("theme" in localStorage) &&
      window.matchMedia("(prefers-color-scheme: dark)").matches)
  ) {
    document.documentElement.classList.add("dark");
    userStore.setTheme("dark");
  } else {
    document.documentElement.classList.remove("dark");
    userStore.setTheme("light");
  }

  const sessionStore = useSessionStore();

  if (to.name === "sign-out") {
    await sessionStore.signOut();
    return navigateTo({ path: "/sign-in" });
  }

  // DON'T REMOVE AUTHLESS_OLD
  if (to.meta.layout === "authless" || to.meta.layout === "authless_old")
    return;

  const clerkUserStore = useClerkUserStore();

  const userCookie = useCookies([]);
  const userCookieValue = userCookie.get("rise-user");
  if (userCookieValue) {
    clerkUserStore.user = userCookieValue;
  }
  const serverHasSession = userCookieValue || sessionStore.hasSession();

  if (!serverHasSession) {
    return navigateTo({ path: "/sign-in", query: { redirect: to.path } });
  }

  if (["sign-in", "sign-up"].includes(String(to.name)) && serverHasSession) {
    return navigateTo("/");
  }
  sessionStore.cleared = false;

  const actionItemsStore = useActionItemsStore();
  actionItemsStore.getActionItems();
});
