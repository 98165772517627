type DocumentStatusTypes = {
  draft: {
    LABEL: string;
    LABEL_COLOR: string;
    COLOR: "primary";
    ICON: string;
    ICON_COLOR: string;
    BORDER_COLOR: string;
  };
  pending: {
    LABEL: string;
    LABEL_COLOR: string;
    COLOR: "yellow";
    ICON: string;
    ICON_COLOR: string;
    BORDER_COLOR: string;
  };
  void: {
    LABEL: string;
    LABEL_COLOR: string;
    COLOR: "red";
    ICON: string;
    ICON_COLOR: string;
    BORDER_COLOR: string;
  };
  declined: {
    LABEL: string;
    LABEL_COLOR: string;
    COLOR: "red";
    ICON: string;
    ICON_COLOR: string;
    BORDER_COLOR: string;
  };
  sent: {
    LABEL: string;
    LABEL_COLOR: string;
    COLOR: "yellow";
    ICON: string;
    ICON_COLOR: string;
    BORDER_COLOR: string;
  };
  complete: {
    LABEL: string;
    LABEL_COLOR: string;
    COLOR: "green";
    ICON: string;
    ICON_COLOR: string;
    BORDER_COLOR: string;
  };
  completed: {
    LABEL: string;
    LABEL_COLOR: string;
    COLOR: "green";
    ICON: string;
    ICON_COLOR: string;
    BORDER_COLOR: string;
  };
};

type DocumentActivityEventType = {
  draft_created: {
    LABEL: string;
    LABEL_COLOR: string;
    COLOR: "primary";
    ICON: string;
    ICON_COLOR: string;
    BORDER_COLOR: string;
  };
  draft_updated: {
    LABEL: string;
    LABEL_COLOR: string;
    COLOR: "primary";
    ICON: string;
    ICON_COLOR: string;
    BORDER_COLOR: string;
  };
  viewed: {
    LABEL: string;
    LABEL_COLOR: string;
    COLOR: "yellow";
    ICON: string;
    ICON_COLOR: string;
    BORDER_COLOR: string;
  };
  voided: {
    LABEL: string;
    LABEL_COLOR: string;
    COLOR: "red";
    ICON: string;
    ICON_COLOR: string;
    BORDER_COLOR: string;
  };
  sent: {
    LABEL: string;
    LABEL_COLOR: string;
    COLOR: "yellow";
    ICON: string;
    ICON_COLOR: string;
    BORDER_COLOR: string;
  };
  signed: {
    LABEL: string;
    LABEL_COLOR: string;
    COLOR: "green";
    ICON: string;
    ICON_COLOR: string;
    BORDER_COLOR: string;
  };
};

export const DOCUMENT_STATUS_TYPES: DocumentStatusTypes = {
  draft: {
    LABEL: "Draft",
    LABEL_COLOR: "text-primary",
    COLOR: "primary",
    ICON: "ri-draft-line",
    ICON_COLOR: "bg-primary-100 text-primary dark:bg-primary dark:text-white",
    BORDER_COLOR: "border-primary",
  },
  void: {
    LABEL: "Voided",
    LABEL_COLOR: "text-red",
    COLOR: "red",
    ICON: "ri-close-line",
    ICON_COLOR: "bg-red-400 text-white",
    BORDER_COLOR: "border-[#E00007]",
  },
  declined: {
    LABEL: "Declined",
    LABEL_COLOR: "text-red",
    COLOR: "red",
    ICON: "ri-close-line",
    ICON_COLOR: "bg-red-400 text-white",
    BORDER_COLOR: "border-[#E00007]",
  },
  sent: {
    LABEL: "Pending",
    LABEL_COLOR: "text-[#776819]",
    COLOR: "yellow",
    ICON: "ri-time-line",
    ICON_COLOR: "bg-[#FFC600] text-white",
    BORDER_COLOR: "border-[#FFC600]",
  },
  pending: {
    LABEL: "Pending",
    LABEL_COLOR: "text-[#776819]",
    COLOR: "yellow",
    ICON: "ri-time-line",
    ICON_COLOR: "bg-[#FFC600] text-white",
    BORDER_COLOR: "border-black",
  },
  complete: {
    LABEL: "Completed",
    LABEL_COLOR: "text-green",
    COLOR: "green",
    ICON: "ri-check-line",
    ICON_COLOR: "bg-green-400 text-white",
    BORDER_COLOR: "border-green-400",
  },
  completed: {
    LABEL: "Signed",
    LABEL_COLOR: "text-green",
    COLOR: "green",
    ICON: "ri-check-line",
    ICON_COLOR: "bg-green-400 text-white",
    BORDER_COLOR: "border-green-400",
  },
};

export const DOCUMENT_ACTIVITY_EVENTS: DocumentActivityEventType = {
  draft_created: {
    LABEL: "Draft Created",
    LABEL_COLOR: "text-primary",
    COLOR: "primary",
    ICON: "ri-draft-line",
    ICON_COLOR: "bg-primary-100 text-primary dark:bg-primary dark:text-white",
    BORDER_COLOR: "border-primary",
  },
  draft_updated: {
    LABEL: "Draft Updated",
    LABEL_COLOR: "text-primary",
    COLOR: "primary",
    ICON: "ri-draft-line",
    ICON_COLOR: "bg-primary-100 text-primary dark:bg-primary dark:text-white",
    BORDER_COLOR: "border-primary",
  },
  voided: {
    LABEL: "Voided",
    LABEL_COLOR: "text-red",
    COLOR: "red",
    ICON: "ri-close-line",
    ICON_COLOR: "bg-red-400 text-white",
    BORDER_COLOR: "border-[#E00007]",
  },
  sent: {
    LABEL: "Sent",
    LABEL_COLOR: "text-[#776819]",
    COLOR: "yellow",
    ICON: "ri-time-line",
    ICON_COLOR: "bg-[#FFC600] text-white",
    BORDER_COLOR: "border-[#FFC600]",
  },
  viewed: {
    LABEL: "Viewed",
    LABEL_COLOR: "text-[#776819]",
    COLOR: "yellow",
    ICON: "ri-time-line",
    ICON_COLOR: "bg-[#FFC600] text-white",
    BORDER_COLOR: "border-black",
  },
  signed: {
    LABEL: "Completed",
    LABEL_COLOR: "text-green",
    COLOR: "green",
    ICON: "ri-check-line",
    ICON_COLOR: "bg-green-400 text-white",
    BORDER_COLOR: "border-green-400",
  },
};

export const LIST_VIEW_TYPES = {
  CARD: "card",
  GRID: "grid",
};

export const mobileRestrictedRoutesConfig = [
  {
    route: "/document-builder",
    redirectUrl: "/document-list",
    headerText: "New Document",
    bodyText:
      "In order to create a new document please open the app from a desktop device.",
  },
  {
    route: "/templates/create",
    redirectUrl: "/templates",
    headerText: "New Template",
    bodyText:
      "In order to create a new template please open the app from a desktop device.",
  },
  {
    route: "/templates/edit",
    redirectUrl: "/templates",
    headerText: "New Template",
    bodyText:
      "In order to create a new template please open the app from a desktop device.",
  },
];
