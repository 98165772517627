import revive_payload_client_MnITycfjtI from "/data/runners/three/_work/apps-monorepo/apps-monorepo/node_modules/.pnpm/nuxt@3.14.159_@types+node@20.17.6_eslint@8.57.1_typescript@5.4.5_vite@5.4.11/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_9k64OmUbKn from "/data/runners/three/_work/apps-monorepo/apps-monorepo/node_modules/.pnpm/nuxt@3.14.159_@types+node@20.17.6_eslint@8.57.1_typescript@5.4.5_vite@5.4.11/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_SefTgEKlKL from "/data/runners/three/_work/apps-monorepo/apps-monorepo/node_modules/.pnpm/nuxt@3.14.159_@types+node@20.17.6_eslint@8.57.1_typescript@5.4.5_vite@5.4.11/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_dwdaXodG79 from "/data/runners/three/_work/apps-monorepo/apps-monorepo/node_modules/.pnpm/nuxt@3.14.159_@types+node@20.17.6_eslint@8.57.1_typescript@5.4.5_vite@5.4.11/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_OZgnrcbHWb from "/data/runners/three/_work/apps-monorepo/apps-monorepo/node_modules/.pnpm/nuxt@3.14.159_@types+node@20.17.6_eslint@8.57.1_typescript@5.4.5_vite@5.4.11/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_lUySGc06q4 from "/data/runners/three/_work/apps-monorepo/apps-monorepo/node_modules/.pnpm/nuxt@3.14.159_@types+node@20.17.6_eslint@8.57.1_typescript@5.4.5_vite@5.4.11/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_M2PBdYvXjh from "/data/runners/three/_work/apps-monorepo/apps-monorepo/node_modules/.pnpm/nuxt@3.14.159_@types+node@20.17.6_eslint@8.57.1_typescript@5.4.5_vite@5.4.11/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_q5aR4e25oP from "/data/runners/three/_work/apps-monorepo/apps-monorepo/node_modules/.pnpm/@pinia+nuxt@0.4.11_typescript@5.4.5_vue@3.5.13/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app/.nuxt/components.plugin.mjs";
import prefetch_client_5lgnZuUr5J from "/data/runners/three/_work/apps-monorepo/apps-monorepo/node_modules/.pnpm/nuxt@3.14.159_@types+node@20.17.6_eslint@8.57.1_typescript@5.4.5_vite@5.4.11/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import switch_locale_path_ssr_BERjj758uX from "/data/runners/three/_work/apps-monorepo/apps-monorepo/node_modules/.pnpm/@nuxtjs+i18n@8.5.6_rollup@4.27.2_vue@3.5.13/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_9iogELV5In from "/data/runners/three/_work/apps-monorepo/apps-monorepo/node_modules/.pnpm/@nuxtjs+i18n@8.5.6_rollup@4.27.2_vue@3.5.13/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import floating_vue_EIcJ7xiw0h from "/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app/.nuxt/floating-vue.mjs";
import clerk_client_jflYVTgoiS from "/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app-documents/plugins/clerk.client.ts";
import dateFns_ZBbwsIHFEa from "/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app-documents/plugins/dateFns.ts";
import fets_7ogS6BrVel from "/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app-documents/plugins/fets.ts";
import filters_8kGZCBi0WW from "/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app-documents/plugins/filters.ts";
import icons_02EJe3sOY0 from "/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app-documents/plugins/icons.ts";
import lottie_PcnWq2UtGV from "/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app-documents/plugins/lottie.ts";
import mask_TwBz6ZEcNV from "/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app-documents/plugins/mask.ts";
import money_PQWPrX57Wd from "/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app-documents/plugins/money.ts";
import pinia_NgZUKvBapq from "/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app-documents/plugins/pinia.ts";
import places_YMDXUo3IvQ from "/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app-documents/plugins/places.ts";
import pusher_WNI1xtQiGb from "/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app-documents/plugins/pusher.ts";
import recaptcha_gQiECi7oK2 from "/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app-documents/plugins/recaptcha.ts";
import select_zFd1sEfhcW from "/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app-documents/plugins/select.ts";
import vCalendar_client_57eD7UYoSR from "/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app-documents/plugins/vCalendar.client.ts";
import apexCharts_client_ILYcaWERKg from "/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app/plugins/apexCharts.client.ts";
import clerk_client_82tfzadmlf from "/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app/plugins/clerk.client.ts";
import dateFns_TubOpgHRRM from "/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app/plugins/dateFns.ts";
import fets_ijYEFpGoED from "/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app/plugins/fets.ts";
import filters_VNgPjx6zHj from "/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app/plugins/filters.ts";
import flags_p3tEupiaZS from "/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app/plugins/flags.ts";
import icons_sncVX745v6 from "/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app/plugins/icons.ts";
import lottie_q1asFBKAuo from "/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app/plugins/lottie.ts";
import mask_Tk3SUcMqzt from "/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app/plugins/mask.ts";
import money_JsSmk5CwyU from "/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app/plugins/money.ts";
import pinia_client_l2mvTNw9P0 from "/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app/plugins/pinia.client.ts";
import places_wGZnNGyAIQ from "/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app/plugins/places.ts";
import pusher_T6fDwDIeLY from "/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app/plugins/pusher.ts";
import recaptcha_85gNSCNFUU from "/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app/plugins/recaptcha.ts";
import select_tI36lTGDUc from "/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app/plugins/select.ts";
import vCalendar_client_FEmB2rjZFJ from "/data/runners/three/_work/apps-monorepo/apps-monorepo/apps/app/plugins/vCalendar.client.ts";
export default [
  revive_payload_client_MnITycfjtI,
  unhead_9k64OmUbKn,
  router_SefTgEKlKL,
  payload_client_dwdaXodG79,
  navigation_repaint_client_OZgnrcbHWb,
  check_outdated_build_client_lUySGc06q4,
  chunk_reload_client_M2PBdYvXjh,
  plugin_vue3_q5aR4e25oP,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5lgnZuUr5J,
  switch_locale_path_ssr_BERjj758uX,
  i18n_9iogELV5In,
  floating_vue_EIcJ7xiw0h,
  clerk_client_jflYVTgoiS,
  dateFns_ZBbwsIHFEa,
  fets_7ogS6BrVel,
  filters_8kGZCBi0WW,
  icons_02EJe3sOY0,
  lottie_PcnWq2UtGV,
  mask_TwBz6ZEcNV,
  money_PQWPrX57Wd,
  pinia_NgZUKvBapq,
  places_YMDXUo3IvQ,
  pusher_WNI1xtQiGb,
  recaptcha_gQiECi7oK2,
  select_zFd1sEfhcW,
  vCalendar_client_57eD7UYoSR,
  apexCharts_client_ILYcaWERKg,
  clerk_client_82tfzadmlf,
  dateFns_TubOpgHRRM,
  fets_ijYEFpGoED,
  filters_VNgPjx6zHj,
  flags_p3tEupiaZS,
  icons_sncVX745v6,
  lottie_q1asFBKAuo,
  mask_Tk3SUcMqzt,
  money_JsSmk5CwyU,
  pinia_client_l2mvTNw9P0,
  places_wGZnNGyAIQ,
  pusher_T6fDwDIeLY,
  recaptcha_85gNSCNFUU,
  select_tI36lTGDUc,
  vCalendar_client_FEmB2rjZFJ
]